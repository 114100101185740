import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js/features/array";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/array/virtual/includes";
import "core-js/features/number/is-nan";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import ReactGA from "react-ga";
ReactGA.initialize("UA-163863306-1");

axios.defaults.baseURL = "https://fes-sociologia.com/";
global.urlHome = "https://fes-sociologia.com/";
global.captcha = "6LcZ4sgZAAAAAL7qIySInpk01h58xFWyWRfTGGh3";
global.socialShare = "https://admin.fes-sociologia.com/social-info.php";
global.urlAdmin = "https://admin.fes-sociologia.com/";
global.editorApiKey = "xbwqxblajntrmpdqkmowglwppulvlsk7aco3clqki0u92vox"

if (window.location.host.startsWith("www")) {
	window.location = "https://fes-sociologia.com" + window.location.pathname;
	//window.location = "https://webfes.espartup.com" + window.location.pathname;
}
//axios.defaults.baseURL = "https://webfes.espartup.com/";
//global.urlHome = "https://webfes.espartup.com/";
//global.captcha = "6LcY1sgZAAAAAMi88AXMzS-rCYt0W5j7RcrZuj1Q";
//global.socialShare = "https://fes-admin-test.espartup.com/social-info.php";
//global.urlAdmin = "https://fes.espartup.com/";
global.notWantedCategoriesNews = ["51"];
global.notWantedCategoriesMulti = ["7"];
global.notWantedCategoriesActu = ["12", "13", "14", "15"];
global.priceSingleComite = 18;
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
